import { AutoexchangeSignatures } from '@/modules/Signatures'
import P2PDealBaseSignModel from '@/models/P2P/P2PDealBaseSignModel'

export default class P2PDealPaymentTypeAccept extends P2PDealBaseSignModel{
  constructor (data, deal) {
    const {
      channel,
      chaincode,
      action: { acceptDealPaymentType: action }
    } = AutoexchangeSignatures
    super({ channel, chaincode, action, data, deal })
  }
}
