import moment from 'moment-mini'

export default function (data) {
  const {
    timestamp,
    transaction_message,
    input_output_type,
    user_account_info
  } = data
  const messageType = input_output_type === 'OUTPUT' ? 'out' : 'in'
  const userId = input_output_type === 'OUTPUT' ? localStorage.getItem('user_id') : ''
  const { id, message: messageText } = transaction_message
  const _time = moment.unix(timestamp).format('DD.MM.YYYY hh:mm:ss')
  return {
    timestamp,
    messageType,
    userId,
    id,
    messageText,
    _time
  }
}
