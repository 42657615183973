import Store from '@/store'

export class Currency {
  constructor (name, code, sortOrder) {
    this.name = name
    this.code = code
    this.sortOrder = sortOrder ?? 0
  }

  static currencies = {
    bitcoin: new Currency('bitcoin', 'BTC', 60),
    ethereum: new Currency('ethereum', 'ETH', 50),
    litecoin: new Currency('litecoin', 'LTC', 40),
    dogecoin: new Currency('dogecoin', 'DOGE', 30),
    tether: new Currency('tether', 'USDT', 20),
    lion: new Currency('lion', 'LION', 10),
    zcash: new Currency('zcash', 'ZEC'),
    bitcoingold: new Currency('bitcoingold', 'BTG'),
    ethereumclassic: new Currency('ethereumclassic', 'ETC'),
    dash: new Currency('dash', 'DASH'),
    bitcoincash: new Currency('bitcoincash', 'BCH'),
    USD: new Currency('USD', 'USD', 70),
    'USD.F': new Currency('USD.F', 'USD.F', 75),
    RUBCash: new Currency('RUBCash', 'RUBCash', 76),
    USDCash: new Currency('USDCash', 'USDCash', 76),
    AEDCash: new Currency('AEDCash', 'AEDCash', 76),
    RMBCash: new Currency('RMBCash', 'RMBCash', 76),
    MessageToken: new Currency('MessageToken', 'MessageToken', 77),
    RUB: new Currency('RUB', 'RUB', 80),
    EUR: new Currency('EUR', 'EUR', 90),
    AED: new Currency('AED', 'AED', 100),
    RMB: new Currency('RMB', 'RMB', 110),
  }

  static getByName (name) {
    return Currency.currencies[name.toLowerCase()]
  }

  static currenciesByCode = {
    zec: Currency.currencies.zcash,
    btc: Currency.currencies.bitcoin,
    eth: Currency.currencies.ethereum,
    ltc: Currency.currencies.litecoin,
    btg: Currency.currencies.bitcoingold,
    etc: Currency.currencies.ethereumclassic,
    doge: Currency.currencies.dogecoin,
    dash: Currency.currencies.dash,
    bch: Currency.currencies.bitcoincash,
    lion: Currency.currencies.lion,
    usdt: Currency.currencies.tether,
    USD: Currency.currencies.USD,
    'USD.F': Currency.currencies['USD.F'],
    'RUBCash': Currency.currencies['RUBCash'],
    'USDCash': Currency.currencies['USDCash'],
    'AEDCash': Currency.currencies['AEDCash'],
    'MessageToken': Currency.currencies['MessageToken'],
    'RMBCash': Currency.currencies['RMBCash'],
    RUB: Currency.currencies.RUB,
    EUR: Currency.currencies.EUR,
    AED: Currency.currencies.AED,
    RMB: Currency.currencies.RMB,
  }

  static getByCode (code) {
    return Currency.currenciesByCode[code.toLowerCase()]
  }

  static get (nameOrCode) {
    return Currency.getByName(nameOrCode) || Currency.getByCode(nameOrCode)
  }

  isBitcoinFork () {
    switch (this.code) {
      case 'ZEC':
      case 'BTC':
      case 'LTC':
      case 'BTG':
      case 'DOGE':
      case 'DASH':
      case 'BCH':
        return true
      default:
        return false
    }
  }

  isEthereumFork () {
    switch (this.code) {
      case 'ETH':
      case 'ETC':
        return true
      default:
        return false
    }
  }

  isEthereumToken () {
    switch (this.code) {
      case 'LION':
      case 'USDT':
        return true
      default:
        return false
    }
  }
}

export let currencies = {
  zcash: 'ZEC',
  bitcoin: 'BTC',
  ethereum: 'ETH',
  litecoin: 'LTC',
  bitcoingold: 'BTG',
  ethereumclassic: 'ETC',
  dogecoin: 'DOGE',
  dash: 'DASH',
  bitcoincash: 'BCH',
  lion: 'LION',
  tether: 'USDT',
  USD: 'USD',
  'USD.F': 'USD.F',
  RUB: 'RUB',
  EUR: 'EUR',
  USDCash: 'USDCash',
  AEDCash: 'AEDCash',
  MessageToken: 'MessageToken',
  RMBCash: 'RMBCash',
  RUBCash: 'RUBCash',
  EURCash: 'EURCash',
  AED: 'AED',
  RMB: 'RMB'
}

const currencyFullNames = new Map([
  ['bitcoin', 'bitcoin'],
  ['btc', 'bitcoin'],
  ['bitcoincash', 'bitcoincash'],
  ['bch', 'bitcoincash'],
  ['bitcoingold', 'bitcoingold'],
  ['btg', 'bitcoingold'],
  ['dash', 'dash'],
  ['dogecoin', 'dogecoin'],
  ['doge', 'dogecoin'],
  ['ethereum', 'ethereum'],
  ['eth', 'ethereum'],
  ['ethereumclassic', 'ethereumclassic'],
  ['etc', 'ethereumclassic'],
  ['litecoin', 'litecoin'],
  ['ltc', 'litecoin'],
  ['lion', 'lion'],
  ['tether', 'tether'],
  ['usdt', 'tether'],
  ['zcash', 'zcash'],
  ['usd', 'usd'],
  ['rub', 'rub'],
  ['eur', 'eur'],
  ['aed', 'aed'],
  ['rmb', 'rmb'],
  ['usdcash', 'usdcash'],
  ['rubcash', 'rubcash'],
  ['eurcash', 'eurcash'],
  ['aedcash', 'aedcash'],
  ['messagetoken', 'messagetoken'],
  ['rmbcash', 'rmbcash'],
  ['usd.f', 'usd.f'],
])

export const getCurrencyFullName = name => {
  return name ? currencyFullNames.get(name.toLowerCase()) : undefined
}

const currencyCodes = new Map([
  ['bitcoin', 'btc'],
  ['btc', 'btc'],
  ['bitcoincash', 'bch'],
  ['bch', 'bch'],
  ['bitcoingold', 'btg'],
  ['btg', 'btg'],
  ['dash', 'dash'],
  ['dogecoin', 'doge'],
  ['doge', 'doge'],
  ['ethereum', 'eth'],
  ['eth', 'eth'],
  ['ethereumclassic', 'etc'],
  ['etc', 'etc'],
  ['litecoin', 'ltc'],
  ['ltc', 'ltc'],
  ['lion', 'lion'],
  ['tether', 'usdt'],
  ['usdt', 'usdt'],
  ['zcash', 'zcash'],
  ['usd', 'usd'],
  ['rub', 'rub'],
  ['eur', 'eur'],
  ['aed', 'aed'],
  ['rmb', 'rmb'],
  ['usdcash', 'usdcash'],
  ['rubcash', 'rubcash'],
  ['eurcash', 'eurcash'],
  ['aedcash', 'aedcash'],
  ['messagetoken', 'messagetoken'],
  ['rmbcash', 'rmbcash'],
  ['usd.f', 'usd.f']
])

export const getCurrencyCode = name => {
  return name ? currencyCodes.get(name.toLowerCase())?.toUpperCase() : undefined
}

export function getCurrencyPairSymbol (currency1, currency2, separator = '/') {
  currency1 = getCurrencyFullName(currency1)
  currency2 = getCurrencyFullName(currency2)
  let order = Store.getters.GetExchangeRateConstraint(currency1, currency2)?.currencies_order
  if (order === undefined) return undefined
  let code1 = getCurrencyCode(currency1)
  let code2 = getCurrencyCode(currency2)
  if (currency1 < currency2) {
    return order ? code1 + separator + code2 : code2 + separator + code1
  } else {
    return order ? code2 + separator + code1 : code1 + separator + code2
  }
}

export function testCurrencyPairOrder (leftCurrency, rightCurrency) {
  // leftCurrency = getCurrencyFullName(leftCurrency)
  // rightCurrency = getCurrencyFullName(rightCurrency)
  // let naturalOrder = leftCurrency < rightCurrency
  // let order = Store.getters.GetExchangeRateConstraint(leftCurrency, rightCurrency)?.currencies_order
  let order = Store.getters.getExchangeRateConstraint(leftCurrency, rightCurrency)?.currencies_order
  if (order === undefined) throw new Error('can\'t find order')
  // return naturalOrder === order
  return order
}
