import BaseSignModel from '@/models/BaseSignModel'
import { TransactionSignatures } from '@/modules/Signatures'

export default class TransferRequestModel extends BaseSignModel {
  constructor (data) {
    const {
      channel,
      chaincode,
      action: { lockFundsOnAccount: action }
    } = TransactionSignatures
    super({
      channel,
      chaincode,
      action,
      data
    })
  }

}
